<template>
  <nav class="navigation">
    <ul class="navigation__list">
      <router-link
        v-for="([icon, text, link, submenu], i) in menu"
        :key="i"
        :to="link"
        custom
        v-slot="{ href, navigate, isActive }"
      >
        <li
          :class="{ active: isActive }"
          class="th-px-16 f-button text-decoration-none"
        >
          <a
            class="text-decoration-none"
            :href="href"
            @click="navigate"
            v-html="$translation.t(text)"
          ></a>
        </li>
      </router-link>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      products: [],
    };
  },
  async beforeCreate() {
    // this.products = await this.$axios.get("/api/v1/products", {
    //   params: { locale: this.$route.params.locale },
    // });
  },
  computed: {
    menu() {
      return [
        [
          "mdi-folder",
          "Documents",
          { name: "documents", params: { ...this.$route.params } },
        ],
        [
          "mdi-folder",
          "Cookie Consent Banner",
          { name: "cookie_consent_index", params: { ...this.$route.params } },
        ],
      ];
    },
  },
  methods: {
    linkMatch(link, submenu) {
      return (
        this.$route.matched.find(({ path }) => path === link) ||
        submenu.find((submenu) => {
          let [sublink] = submenu;
          return this.$route.matched.find(({ path, regex }) => {
            if (path === sublink) {
              return true;
            }

            const pattern = new RegExp(regex);
            return !!pattern.test(sublink);
          });
        })
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  &__list {
    list-style: none;
    margin: 0;
    padding: 0;

    @include media-breakpoint-up(lg) {
      height: $header-height;
    }

    > li {
      display: inline-flex;
      /*border-bottom: 3px solid transparent;*/
      align-items: center;
      height: 100%;
      position: relative;

      @include media-breakpoint-down(md) {
        display: block;
        padding: 16px 12px;
        text-align: center;
        border-bottom: 1px solid $border-color;
        height: unset;
      }

      @include media-breakpoint-up(lg) {
        &.active::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 3px;
          width: 100%;
          border-bottom: 3px solid $primary-mid;
        }
      }
    }
  }
}
</style>
