<template>
  <div class="navigation-menu">
    <div class="navigation-menu__content" v-show="open">
      <slot />
    </div>
    <div class="navigation-menu__mobile-toggle" @click="open = !open">
      <v-icon v-if="open">mdi-close</v-icon>
      <v-icon v-if="!open">mdi-menu</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationMenu",
  data() {
    return {
      open: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.navigation-menu {
  position: relative;

  &__content {
    position: fixed;
    top: $header-height;
    bottom: 0;
    left: 0;
    right: 0;
    background: $white;
    height: calc(100vh - #{$header-height});

    @include media-breakpoint-up(lg) {
      display: flex !important;
      align-items: center;
      position: relative;
      background: unset;
      height: auto;
      top: unset;
      bottom: unset;
      left: unset;
      right: unset;
    }
  }

  &__mobile-toggle {
    cursor: pointer;

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
</style>
