<template>
  <div class="default">
    <app-header />
    <v-main>
      <page-content-header />
      <div class="container th-py-32">
        <slot />
      </div>
    </v-main>
  </div>
</template>

<script>
import AppHeader from "../components/Layout/Header/Header";
import { mapState } from "vuex";
import PageContentHeader from "../components/Layout/PageContentHeader";

export default {
  name: "default",
  components: {
    PageContentHeader,
    AppHeader,
  },
  computed: {
    ...mapState({
      tosagree: (state) => state.client.tosagree,
    }),
  },
};
</script>
