<template>
  <div>
    <v-app-bar app :height="56" class="th-py-0 header" elevate-on-scroll>
      <template
        v-if="
          ($vuetify.breakpoint.mdAndDown || sideCollapse) && sideMenuComponent
        "
      >
        <v-app-bar-nav-icon color="primary" @click.stop="drawer = !drawer" />
      </template>
      <header-logo height="24" :light="false" />
      <language-selector v-if="showLanguageSelect" />

      <v-spacer />

      <order-plan-button sm class="d-lg-none th-mr-12" />
      <navigation-menu>
        <navigation class="th-mr-20-md" />

        <div class="text-center" v-if="$vuetify.breakpoint.mdAndDown">
          <v-btn
            color="primary"
            outlined
            class="th-mt-24"
            @click="$store.dispatch('logout')"
          >
            {{ $translation.t("Logout") }}
          </v-btn>
        </div>

        <profile-summary name v-if="$vuetify.breakpoint.lgAndUp" />
      </navigation-menu>
    </v-app-bar>
  </div>
</template>

<script>
import HeaderLogo from "@/components/Layout/Header/HeaderLogo";
import ProfileSummary from "@/components/Layout/Header/ProfileSummary";
import Navigation from "@/components/Layout/Header/Navigation";
import NavigationMenu from "@/components/Layout/Header/NavigationMenu";
import OrderPlanButton from "@/components/Billing/OrderPlanButton";
import LanguageSelector from "@/components/LanguageSelector/LanguageSelector";
import { mapGetters } from "vuex";

export default {
  name: "app-header",
  components: {
    LanguageSelector,
    OrderPlanButton,
    NavigationMenu,
    Navigation,
    ProfileSummary,
    HeaderLogo,
  },
  data: () => ({
    drawer: false,
  }),
  computed: {
    sideCollapse() {
      return this.$route.meta.sideCollapse;
    },
    sideMenuComponent() {
      if (this.$route.meta.sideMenuComponent) {
        return this.$route.meta.sideMenuComponent;
      }
      return null; //() => import("@/components/MainMenu");
    },
    ...mapGetters(["showLanguageSelect"]),
  },
};
</script>

<style lang="scss">
.header {
  border-bottom: 1px solid $border-color !important;
}
</style>
