var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navigation"},[_c('ul',{staticClass:"navigation__list"},_vm._l((_vm.menu),function(ref,i){
var icon = ref[0];
var text = ref[1];
var link = ref[2];
var submenu = ref[3];
return _c('router-link',{key:i,attrs:{"to":link,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"th-px-16 f-button text-decoration-none",class:{ active: isActive }},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":href},domProps:{"innerHTML":_vm._s(_vm.$translation.t(text))},on:{"click":navigate}})])]}}],null,true)})}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }