<template>
  <div
    class="bg-white elevation-2"
    v-if="!$route.meta.hideTitle && $route.meta.title !== false"
  >
    <div class="container th-py-24 d-flex align-items-center">
      <page-title class="flex-grow-1" />
      <div v-if="pageContentHeaderComponent">
        <component :is="pageContentHeaderComponent" />
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/Layout/PageTitle";
export default {
  name: "PageContentHeader",
  components: { PageTitle },
  computed: {
    pageContentHeaderComponent() {
      if (this.$route.meta.pageContentHeaderComponent) {
        return this.$route.meta.pageContentHeaderComponent;
      }

      return null;
    },
  },
};
</script>
