<template>
  <div>
    <h4 class="flex-grow-1 th-m-0" v-html="pageTitle"></h4>
    <p v-if="pageSubTitle" class="body2" v-html="pageSubTitle"></p>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PageTitle",
  computed: {
    pageTitle() {
      const storePageTitle = this.storePageTitle[this.$route.fullPath];

      if (storePageTitle) return storePageTitle;

      const title = this.$route?.meta?.title;

      if (title && typeof title == "string") {
        return this.$translation.t(this.$route?.meta?.title, this.$global);
      }
      return null;
    },
    pageSubTitle() {
      const storePageTitle = this.storePageSubTitle[this.$route.fullPath];

      if (storePageTitle) return storePageTitle;

      const title = this.$route?.meta?.subtitle;

      if (title && typeof title == "string") {
        return this.$translation.t(this.$route?.meta?.subtitle, this.$global);
      }
      return null;
    },
    ...mapState({
      storePageTitle: (state) => state.website.pageTitle,
      storePageSubTitle: (state) => state.website.pageSubTitle,
    }),
  },
};
</script>

<style scoped></style>
